import { eventQBus } from '../types/EventQBus';
import Toggles from '../utils/Toggles';
import tracking from '../utils/Tracking';

export type FeedbackFormTrackingLabels = {
    san_Interaction: 'feedback_ltr' | 'feedback_text';
    'wk.san_ListFeedbackScore': string;
    san_ListFeedbackId?: string;
};

const clickOnceOption: object = { once: true };

let toggles: Toggles;
let feedbackContainer: HTMLElement | null;

/*                                           */
function showSuccessBanner(feedbackTool: Element | null, smiley: Element): void {
    if (feedbackTool) {
        const feedbackSuccessBanner = document.querySelector('.reptile_feedback .reptile_feedback__banner');
        const feedbackContainer = document.querySelector('.reptile_feedback');

        feedbackTool.addEventListener('transitionend', function () {
            feedbackTool.classList.add('reptile_feedback__tool--hidden');
            feedbackSuccessBanner?.classList.remove('reptile_feedback__banner--hidden');

            setTimeout(function () {
                feedbackContainer?.classList.add('reptile_feedback__container--hidden');
                feedbackSuccessBanner?.classList.add('reptile_feedback__banner--hidden');
            }, 5000);
        });
        smiley.classList.add('reptile_feedback__smiley--active');

        feedbackTool.classList.remove('reptile_feedback__tool--visible');
    }
}

/*                                        */
function showSuccessFormBanner(feedbackTool: Element | null, smiley: Element): void {
    if (feedbackTool) {
        const feedbackSuccessBanner = document.querySelector('.reptile_feedback .reptile_feedback__bannerForm');
        const animationBanner = document.querySelector('.reptile_feedback.reptile_feedback__animationBanner');

        feedbackTool.addEventListener('transitionend', function () {
            feedbackTool.classList.add('reptile_feedback__tool--hidden');
            feedbackSuccessBanner?.classList.remove('reptile_feedback__bannerForm--hidden');

            setTimeout(function () {
                feedbackSuccessBanner?.classList.add('reptile_feedback__bannerForm--hidden');
                animationBanner?.classList.remove('reptile_feedback__animationBanner');
                animationBanner?.classList.add('reptile_feedback__animationBanner--hidden');
            }, 5000);
        });
        smiley.classList.add('reptile_feedback__smiley--active');

        feedbackTool.classList.remove('reptile_feedback__tool--visible');
    }
}

function feedbackClick(e: Event): void {
    const smiley: Element = e.target as Element;

    if (feedbackContainer) {
        const feedbackScore = smiley.getAttribute('data-feedback-score') || '';
        trackRating(feedbackScore);

        const feedbackTool = feedbackContainer.querySelector('.reptile_feedback__tool');

        showSuccessBanner(feedbackTool, smiley);
    }
}

function isOpenFeedbackEnabled(): boolean {
    return !toggles.isActive('EMERGENCY_DISABLE_OPEN_QUESTION_FEEDBACK');
}

async function submitMessage(rule: string, text: string, score: number): Promise<Response> {
    return fetch(`/dolittle/feedback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        body: JSON.stringify({
            dresonRule: rule,
            feedbackText: text,
            rating: score,
        }),
    });
}

function animateForInput(feedbackMessageForm: Element) {
    if (feedbackContainer) {
        feedbackContainer.classList.add('reptile_feedback--animationMessage');
        feedbackMessageForm.classList.add('reptile_feedback__message--visible');
    }
}

function animateForBanner() {
    if (feedbackContainer) {
        feedbackContainer.classList.replace('reptile_feedback--animationMessage', 'reptile_feedback__animationBanner');
    }
}

function feedbackWithMessageClick(e: Event): void {
    const smiley: Element = e.target as Element;

    if (feedbackContainer) {
        const feedbackScore = smiley.getAttribute('data-feedback-score') || '';
        const feedbackTool = feedbackContainer.querySelector('.reptile_feedback__tool');
        const feedbackMessageForm = document.getElementById('reptile-feedback-message');

        if (feedbackTool && feedbackMessageForm) {
            activateTextCounter(feedbackTool);

            const negativeTexts = feedbackMessageForm.querySelectorAll('.reptile_feedback__negative');
            const positiveTexts = feedbackMessageForm.querySelectorAll('.reptile_feedback__positive');
            const isNegative = parseInt(feedbackScore, 10) <= 3;

            negativeTexts.forEach((text) => {
                text.classList.toggle('reptile_feedback__negative--visible', isNegative);
            });

            positiveTexts.forEach((text) => {
                text.classList.toggle('reptile_feedback__positive--visible', !isNegative);
            });

            trackRating(feedbackScore);
            animateForInput(feedbackMessageForm);

            Array.from(document.getElementsByClassName('js_reptile_cancel_feedback')).forEach((cancelButton) => {
                cancelButton?.addEventListener(
                    'click',
                    () => {
                        animateForBanner();
                        showSuccessFormBanner(feedbackTool, smiley);
                    },
                    clickOnceOption,
                );
            });

            Array.from(document.getElementsByClassName('js_reptile_submit_feedback')).forEach((submitButton) => {
                submitButton.addEventListener(
                    'click',
                    () => {
                        const rule = document.getElementById('reptile-tilelist-bracket')?.dataset.rule || '';
                        const textArea = feedbackTool.querySelector(
                            '#reptile-feedback-text-area',
                        ) as HTMLTextAreaElement;
                        const text = textArea?.value || '';

                        const promiseResult = Promise.resolve(submitMessage(rule, text, parseInt(feedbackScore, 10)));
                        promiseResult.then((r) => {
                            if (r.status < 300) {
                                const jsonResult = Promise.resolve(r.json());
                                jsonResult.then((json) => {
                                    if (json.feedbackId) {
                                        const textTracking: FeedbackFormTrackingLabels = {
                                            san_Interaction: 'feedback_text',
                                            'wk.san_ListFeedbackScore': feedbackScore,
                                            san_ListFeedbackId: json.feedbackId,
                                        };
                                        tracking.submitEvent(textTracking);
                                    }
                                });
                            }
                        });

                        animateForBanner();
                        showSuccessFormBanner(feedbackTool, smiley);
                    },
                    clickOnceOption,
                );
            });
        }
    }
}

/**
 *
 *
 *
 */
function activateTextCounter(container: Element) {
    if (!container.querySelector('.pl_counter')) {
        eventQBus.emit('assets.formular.counter', '#reptile-feedback-message .pl_textarea');
    }
}

function trackRating(rating: string) {
    const ratingLabels: FeedbackFormTrackingLabels = {
        san_Interaction: 'feedback_ltr',
        'wk.san_ListFeedbackScore': rating,
    };
    tracking.submitEvent(ratingLabels);
}

function registerClickEvents(): void {
    feedbackContainer = document.querySelector('.reptile_feedback');

    Array.from(document.getElementsByClassName('js_reptile_feedback')).forEach((smiley) =>
        smiley.addEventListener(
            'click',
            isOpenFeedbackEnabled() ? feedbackWithMessageClick : feedbackClick,
            clickOnceOption,
        ),
    );

    if (isOpenFeedbackEnabled()) {
        feedbackContainer?.classList.add('reptile_feedback--animation');
    }
}

export function registerFeedbackForm() {
    toggles = new Toggles();

    registerClickEvents();
    eventQBus.on('ftfind.tilelist.loaded', registerClickEvents);
}

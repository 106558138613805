import { type DataContainer, tracking } from '@otto-ec/tracking-bct';
import { TrackingLabels } from '../types/Tracking';

class Tracking {
    static convertLabelsToDataContainer(labels: Partial<TrackingLabels>): DataContainer {
        const dataContainer: DataContainer = {};

        Object.keys(labels).forEach((key) => {
            const value = labels[key as keyof TrackingLabels];
            if (value !== undefined) {
                dataContainer[key] = Array.isArray(value) ? value : [value];
            }
        });

        return dataContainer;
    }

    submitEvent(labels: Partial<TrackingLabels>): void {
        tracking.submitEvent(Tracking.convertLabelsToDataContainer(labels));
    }
}

export default new Tracking();

import { EventLoaderEvents } from '@otto-ec/global-resources/event-loader';
import { eventQBus as eventQBusRaw } from '@otto-ec/global-resources/event-q-bus';
import { TilelistTopics } from './Tilelist';

type AssetsCounterTopic = {
    'assets.formular.counter': [string]; /*                                                      */
};

export type Topics = EventLoaderEvents & TilelistTopics & AssetsCounterTopic;

export const eventQBus = eventQBusRaw.with<Topics>();
